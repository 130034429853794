export const config = {
	ThunderstormModule: {
		appName: 'Messaging STG'
	},
	XhrHttpModule: {
		origin: "https://us-central1-ir-q-messaging-staging.cloudfunctions.net/api",
		timeout: 30000,
		compress: false
	},
	ServiceWorker: {
		firebaseVersion: '8.2.6'
	},
	EnvironmentModule: {
		envName: "staging",
		kasperoUrl: "https://elliq-env-map-staging.firebaseapp.com"
	},
	FirebaseModule: {
		local: {
			apiKey: "AIzaSyA9eiticFP6WVuUB__jXc4Rwf-nphHkZVE",
			authDomain: "ir-q-messaging-staging.firebaseapp.com",
			databaseURL: "https://ir-q-messaging-staging-default-rtdb.firebaseio.com",
			projectId: "ir-q-messaging-staging",
			storageBucket: "ir-q-messaging-staging.appspot.com",
			messagingSenderId: "182613551181",
			appId: "1:182613551181:web:904ed13ac6a091553a5404",
			measurementId: "G-8VGDJ3BVYS"
		}
	},
	PushPubSubModule: {
		publicKeyBase64: 'BNm5_wRG75GkK8yy1la9dVY6SnoWmdYKmSujQrMWEIAcwOFYA0Aae-rWCJZg2iYCPsO3yXdm3-QoRy-DzzsBJaQ',
		swFileName: 'sw.js'
	},
	ForceUpgrade: {
		assertVersionUrl: "/v1/version/assert"
	},
	ServerApiModule: {
		serverApiUrl: "https://elliq-api-server-staging.s.elliq.co:443/api"
	},
	SupportModule: {
		supportUrls: ["https://ir-q-support-staging.elliq.co", "https://staging.support.elliq.co"]
	}
};
